<template>
    <div
        v-loading="loading"
        element-loading-background="rgba(122, 122, 122, 0.1)"
        element-loading-text="Please wait"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        style="z-index: 1"
    >
        <RoundRobinFilter 
            :roles="roles"
            :skyeTypesList="skyeTypesList"
            :showRoundRobinRoleAndTypeFilter="showRoundRobinRoleAndTypeFilter"
        />

        <div class="card mt-5" v-if="loadOnce">
            <div class="card-body">
                Please wait while fetching the data....
            </div>
        </div>

        <RoundRobinTable 
            v-else
            :datas="lists"
            :footer-data="footerData"
            @refreshListing="listing"
        />

    </div>

</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { userRoundRobinP } from '@/store/composable/Listing.js'
import { useStore } from 'vuex'
import RoundRobinFilter from '@/views/NEW/User/Models/RoundRobinFilter.vue'
import RoundRobinTable from '@/views/NEW/User/Models/RoundRobinTable.vue'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoleBasedConditions } from '@/store/composable/User'

onMounted(async() => {
    setCurrentPageBreadcrumbs('Set Round Robin', ['Users'])

    getRoles()
    listing()
})
const { showRoundRobinRoleAndTypeFilter } = useRoleBasedConditions()

const store = useStore()
const roles = ref([])
const lists = ref([])
const loading = ref(true)
const loadOnce = ref(true)
const footerData = ref({
    from: 0,
    to: 0,
    total: 0
})

watch(() => userRoundRobinP.value, value => {
    listing()
}, {deep: true})

async function getRoles() {
    const params = {
        set_round_robin: true,
        linkType: 'user-roles'
    }
    const response = await useUpdateCustomerData(params)
    roles.value = response.data.data.roles
}

const skyeTypesList = computed(() => {
    return [
        { value: 1, label: 'CAR' },
        { value: 2, label: 'HEALTH' },
        { value: 3, label: 'MISK' }
    ]
});

async function listing() {
    loading.value = true
    const params = {
        ...userRoundRobinP.value,
        linkType: 'roundrobin-listing'
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const response = await useUpdateCustomerData(params)
    lists.value = store.getters.getRoundRobinUserLists(response.data.data.users.data)
    footerData.value.from = response.data.data.users.from
    footerData.value.to = response.data.data.users.to
    footerData.value.total = response.data.data.users.total
    loading.value = false
    loadOnce.value = false
}

</script>