<template>
    <div class="card card-user-listing container container-lead bg-white border-radius-4">
        <div class="card-header border-0 p-0 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center position search-block">
                <div class="search-field-block">
                    <el-input
                        placeholder="Search User"
                        v-model="userSearch"
                        @keydown.enter.prevent="keywordPress"
                        ref="sfilter"
                    >
                        <template #prefix>
                            <span class="svg-icon svg-icon-1 me-12">
                                <inline-svg
                                    src="/media/icons/duotune/general/gen021.svg"
                                />
                            </span>
                        </template>
                    </el-input>
                </div>

                <div class="bd-highlight search-buttons-block">
                    <button 
                        type="button"
                        class="btn btn-light-info rounded-0 cursor-pointer"
                        @click="keywordPress"
                    >
                        Search
                    </button>
                    <button 
                        type="button"
                        style="border-radius: 0"
                        class="btn btn-light-info"
                        @click="setuserRoundRobinP"
                    >
                        Reset
                    </button>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-end">

                <div class="d-flex align-items-center position-relative me-2" v-if="showRoundRobinRoleAndTypeFilter">
                    <el-select
                        v-model="userRoundRobinP.skye_type"
                        @change="userRoundRobinP.page = 1"
                    >
                        <el-option :value="0" label="Select Type" />
                        <el-option v-for="skyeTypes in skyeTypesList" :key="skyeTypes.value" :label="skyeTypes.label" :value="skyeTypes.value" />
                    </el-select>
                </div>

                <div class="d-flex align-items-center position-relative me-2" v-if="showRoundRobinRoleAndTypeFilter">
                    <el-select
                        v-model="userRoundRobinP.role_id"
                        @change="userRoundRobinP.page = 1"
                    >
                        <el-option :value="0" label="Select Role" />
                        <el-option v-for="role in roles" :key="role.value" :label="role.label" :value="role.value" />
                    </el-select>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { userRoundRobinP, userSearch, setuserRoundRobinP } from '@/store/composable/Listing.js'
import { useRouter } from 'vue-router'

const props = defineProps({
    roles: {
        type: Function,
        required: true
    },
    skyeTypesList: {
        type: Function,
        required: true
    },
    showRoundRobinRoleAndTypeFilter: {
        type: Boolean,
        required: false
    }
})

const router = useRouter()

function keywordPress() {
    userRoundRobinP.value.keyword = userSearch.value
}
</script>